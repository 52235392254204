<template>
  <div>
    <tab-headers>
      Your Profile
      <template #text> Update your profile here </template>
    </tab-headers>
    <p v-if="user.status === 'disapproved'" class="font-bold text-base">
      Your account is presently suspended due to reason sent to your email,
      kindly update your profile and click here to submit
      <span class="text-red-500 cursor-pointer" @click="submitApprovalRequest">
        Approval Request
      </span>
    </p>
    <div class="bg-white shadow rounded-md">
      <a-tabs
        v-model="activeKey"
        size="small"
        :style="{ width: '100%' }"
        :tab-position="width > 640 ? 'top' : 'left'"
        default-active-key="1"
        @change="changeTab"
      >
        <a-tab-pane key="0" tab="Personal Information"> </a-tab-pane>
        <a-tab-pane key="1" tab="Work Information"> </a-tab-pane>
        <a-tab-pane key="2" tab="Accreditation"> </a-tab-pane>
        <a-tab-pane key="3" tab="Security"> </a-tab-pane>
      </a-tabs>
    </div>
    <div class="bg-white mt-4 shadow rounded p-3">
      <div v-if="activeKey === '0'">
        <div class="px-4 pt-4 pb-6">
          <div
            class="
              shadow
              rounded
              w-full
              md:flex
              items-center
              justify-start
              px-4
              mb-5
            "
          >
            <div class="w-3/12 py-2">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                @change="handleChange"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </div>
            <div class="w-auto">
              <h2 class="font-semibold">Profile Image</h2>
              <div class="mb-2 md:mb-0">
                Click on the Image to upload/change profile image
              </div>
            </div>
          </div>
          <a-form-model layout="vertical">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
              <a-form-item label="First Name">
                <a-input
                  v-model="userCopy.firstName"
                  placeholder="First Name"
                ></a-input>
              </a-form-item>
              <a-form-item label="Last Name">
                <a-input
                  v-model="userCopy.lastName"
                  placeholder="Last Name"
                ></a-input>
              </a-form-item>
              <a-form-item v-if="user.role === 'lawyer'" label="Middle Name">
                <a-input
                  v-model="userCopy.otherName"
                  placeholder="Middle Name"
                ></a-input>
              </a-form-item>
              <a-form-item v-if="user.role === 'lawyer'" label="Gender">
                <a-select v-model="userCopy.gender" placeholder="Gender">
                  <a-select-option value="male">Male</a-select-option>
                  <a-select-option value="female">Female</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Date of Birth">
                <a-input
                  v-model="userCopy.dateOfBirth"
                  type="date"
                  placeholder="Date of Birth"
                ></a-input>
              </a-form-item>
              <a-form-item label="Email Address">
                <a-input
                  v-model="userCopy.email"
                  type="email"
                  disabled
                  placeholder="Email Address"
                ></a-input>
              </a-form-item>
              <a-form-item label="Phone Number">
                <a-input
                  v-model="userCopy.phone"
                  type="text"
                  placeholder="Phone Number"
                ></a-input>
              </a-form-item>
              <a-form-item label="Address">
                <a-input
                  v-model="userCopy.address"
                  placeholder="Address"
                ></a-input>
              </a-form-item>
            </div>
          </a-form-model>
          <a-button
            :loading="updatingUsers"
            type="primary"
            @click="updateProfile"
            >Save &amp; Next</a-button
          >
        </div>
      </div>
      <div v-if="activeKey === '1'">
        <div class="px-4 pt-4 pb-6">
          <a-form-model layout="vertical">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
              <a-form-model-item label="State" prop="state">
                <a-select
                  v-model="userCopy.state"
                  show-search
                  :loading="states.length === 0"
                  placeholder="Select a state"
                  :filter-option="filterOptionState"
                  @change="getTowns"
                >
                  <a-select-option
                    v-for="state in states"
                    :key="state._id"
                    :value="state._id"
                  >
                    {{ state.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="Town" prop="town">
                <a-select
                  v-model="userCopy.town"
                  show-search
                  :loading="towns.length === 0"
                  placeholder="Select a town"
                  :filter-options="filterOptionTown"
                >
                  <a-select-option
                    v-for="town in towns"
                    :key="town._id"
                    :value="town._id"
                  >
                    {{ town.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="Business Address"
                prop="businessAddress"
              >
                <a-input
                  v-model="userCopy.businessAddress"
                  placeholder="Business Address"
                  type="text"
                />
              </a-form-model-item>
              <a-form-model-item label="Law Firm" prop="lawFirm">
                <a-input
                  v-model="userCopy.lawFirm"
                  placeholder="Law Firm"
                  type="text"
                />
              </a-form-model-item>
            </div>
            <a-form-model-item
              v-if="!updatingUsers"
              label="Area of Practice"
              prop="areasOfPractice"
              extra="Please select all your Area of Expertise, also note the the first Four selected Area of Expertise will be shown to users"
            >
              <a-select
                v-model="userCopy.areasOfPractice"
                :loading="specialty.length === 0"
                placeholder="Please select your area fo expertise"
                mode="multiple"
                :filter-options="filterOptionAreaOfExpertise"
              >
                <a-select-option
                  v-for="special in specialty"
                  :key="special._id"
                  :value="special._id"
                >
                  {{ special.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
          <a-button
            :loading="updatingUsers"
            type="primary"
            @click="updateProfile"
            >Save &amp; Next</a-button
          >
        </div>
      </div>
      <div v-if="activeKey === '2'">
        <div class="px-4 pt-4 pb-6">
          <a-form-model layout="vertical">
            <div class="grid md:grid-cols-2 grid-cols-1">
              <a-form-item label="Year Called To Bar">
                <a-input
                  v-model="userCopy.callToBarYear"
                  placeholder="Year Called To Bar"
                  accept="image/*,.pdf"
                ></a-input>
              </a-form-item>
            </div>
            <h2 class="font-semibold my-2">Upload Certificate</h2>
            <a-form-item label="Call to Bar Certificate">
              <input
                type="file"
                name="file"
                value="Upload File"
                accept="image/*,.pdf"
                @change="handleBarCertificate"
              />
              <!-- <a-button> <a-icon type="upload" /> Click to Upload </a-button> -->
              <!-- </input> -->
              <div v-if="userCopy.callToBarCertificate" class="mt-3">
                Download previously uploaded
                <a
                  download
                  target="_blank"
                  :href="userCopy.callToBarCertificate"
                  >Call to Bar Certificate</a
                >
              </div>
            </a-form-item>
            <a-form-item
              label="Proof of Payment of Bar Practicing Fee (Current Year)"
            >
              <input
                accept="image/*,.pdf"
                type="file"
                name="file"
                @change="handleChangeForProof"
              />
              <!-- <a-button> <a-icon type="upload" /> Click to Upload </a-button> -->
              <!-- </input> -->
              <div v-if="userCopy.proofOfPayment" class="mt-3">
                Download previously uploaded proof of
                <a download target="_blank" :href="userCopy.proofOfPayment"
                  >Payment</a
                >
              </div>
            </a-form-item>
            <a-form-item v-if="user.role === 'lawfirm'" label="CAC Document">
              <input
                accept="image/*,.pdf"
                type="file"
                name="file"
                @change="handleChangeForProof"
              />
              <!-- <a-button> <a-icon type="upload" /> Click to Upload </a-button> -->
              <!-- </input> -->
              <div v-if="userCopy.cacDocument" class="mt-3">
                Download previously uploaded proof of
                <a download target="_blank" :href="userCopy.cacDocument"
                  >CAC document</a
                >
              </div>
            </a-form-item>
            <div class="md:flex items-end justify-between">
              <a-form-item label="Other Professional Certificate">
                <input
                  type="file"
                  name="file"
                  accept="image/*,.pdf"
                  @change="handleChangeForMultiple"
                />
                <!-- <a-button>
                    <a-icon type="upload" /> Click to Upload
                  </a-button>
                </input> -->
              </a-form-item>
              <!-- <a-button
                class="mt-3 md:mt-0"
                :disabled="fileOthers == ''"
                type=""
                @click="uploadFiles"
                >Upload other Docs</a-button
              > -->
            </div>
          </a-form-model>
          <a-button
            :loading="updatingUsers"
            type="primary"
            @click="updateProfile"
            >Save</a-button
          >
        </div>
      </div>
      <div v-if="activeKey === '3'">
        <div class="px-4 pt-4 pb-6">
          <h3 class="font-semibold text-lg mb-2">Change Password</h3>
          <a-form-model
            ref="securityForm"
            :rules="rules"
            :model="password"
            layout="vertical"
          >
            <a-form-model-item has-feedback label="Old Password" prop="'old">
              <a-input
                v-model="password.old"
                type="password"
                placeholder="Old Password"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item has-feedback label="New Password" prop="new">
              <a-input
                v-model="password.new"
                type="password"
                placeholder="New Password"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              has-feedback=""
              label="Confirm New Password"
              prop="confirm"
            >
              <a-input
                v-model="password.confirm"
                type="password"
                placeholder="Retype New Password"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
          <a-button
            :loading="password.updating"
            type="primary"
            @click="changePassword"
            >Update</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import siteDataApi from '../../api/site_data'
import userApi from '../../api/user'
import authApi from '../../api/auth'
import TabHeaders from '../../components/typography/TabHeaders.vue'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  name: 'LawyerProfile',
  components: { TabHeaders },
  layout: 'lawyerDashboard',
  data() {
    const passwordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.password.confirm !== '') {
          this.$refs.securityForm.validateField('conform')
        }
        callback()
      }
    }
    const confirmPasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.password.new) {
        callback(new Error("Password inputs don't match!"))
      } else {
        callback()
      }
    }
    return {
      loadingCAC: false,
      activeKey: '0',
      mode: 'top',
      loading: false,
      updatingUsers: false,
      imageUrl: '',
      states: [],
      towns: [],
      specialty: [],
      loadingProof: false,
      loadingBarCert: false,
      userCopy: {},
      activeTab: '0',
      fileList: [],
      fileOthers: '',
      password: {
        old: '',
        new: '',
        confirm: '',
        updating: false
      },
      rules: {
        old: [
          {
            message: 'Please enter you present password',
            required: true
          }
        ],
        new: [
          {
            message: 'Password cannot be empty',
            required: true
          },
          {
            message: 'Password must be at least 6 characters',
            min: 8
          },
          {
            trigger: 'change',
            validator: passwordValidator
          }
        ],
        confirm: [
          {
            required: true,
            message: 'Enter your password again'
          },
          {
            validator: confirmPasswordValidator,
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    }
  },
  mounted() {
    // const x = JSON.parse(JSON.stringify(this.user))

    // this.userCopy = x
    // this.userCopy.areasOfPractice = this.userCopy.areasOfPractice
    //   .filter((d) => d.practice !== null)
    //   .map((data) => data.practice._id)

    // this.imageUrl = this.userCopy.avatar
    this.getTowns()
  },
  async created() {
    // eslint-disable-next-line no-unused-vars
    await this.$store.dispatch('auth/getProfileUpdate').then(res => {
      const x = JSON.parse(JSON.stringify(this.user))

      this.userCopy = x
      this.userCopy.areasOfPractice = this.userCopy.areasOfPractice
        .filter(d => d.practice !== null)
        .map(data => data.practice._id)

      this.imageUrl = this.userCopy.avatar
    })
    this.getStates()
    this.getSpecialty()
  },
  methods: {
    async submitApprovalRequest() {
      const req = await userApi(this.axios).requestApproval()
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.message
        })
        return
      }
      this.$notification.success({
        message: 'Request Successful',
        description: req.message
      })
    },
    changeTab(tab) {
      this.activeKey = String(tab)
    },
    async getTowns() {
      if (this.userCopy.state === '') {
        this.towns = []
        return
      }
      const req = await siteDataApi(this.axios).get(
        { type: 'town' },
        { state: this.userCopy.state }
      )
      this.towns = req.data.data
    },
    async changePassword() {
      this.password.updating = true
      const req = await authApi(this.axios).changePassword({
        oldPassword: this.password.old,
        password: this.password.new,
        confirmPassword: this.password.confirm
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to change password',
          description: req.message
        })
        this.password.updating = false
        return
      }
      this.$notification.success({
        message: 'Password changed successful',
        description: req.message
      })
      this.password.updating = false
      this.password = {
        new: '',
        old: '',
        confirm: ''
      }
    },
    async updateProfile() {
      this.updatingUsers = true
      const AOP = []
      this.userCopy.areasOfPractice.forEach((e, i) => {
        AOP.push({
          practice: e,
          isPrimary: i <= 5
        })
      })
      this.userCopy.areasOfPractice = AOP
      const req = await userApi(this.axios).updateProfile({
        data: this.userCopy
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to update Profile',
          description: req.message
        })
        this.updatingUsers = false
        return
      }
      this.$store
        .dispatch('auth/getProfileUpdate')
        .then(res => {
          const x = JSON.parse(JSON.stringify(res.data))

          this.userCopy = x
          this.userCopy.areasOfPractice = this.userCopy.areasOfPractice
            .filter(d => d.practice !== null)
            .map(data => data.practice._id)
        })
        .catch(err => {
          this.$notification.error({
            message: 'Ops',
            description: err.message
          })
        })
      this.updatingUsers = false
      if (this.activeKey !== '3') {
        this.activeKey = String(Number(this.activeKey) + 1)
      } else {
        this.activeKey = String(0)
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message
      })
    },
    async getStates() {
      const req = await siteDataApi(this.axios).get(
        { type: 'state' },
        { limit: 0 }
      )
      this.states = req.data.data
    },
    async getTownsAll() {
      const req = await siteDataApi(this.axios).get(
        { type: 'town' },
        { limit: 0 }
      )
      this.towns = req.data.data
    },
    async getSpecialty() {
      const req = await siteDataApi(this.axios).get(
        { type: 'speciality' },
        { limit: 0 }
      )
      this.specialty = req.data.data
    },
    filterOptionState(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    filterOptionTown(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    filterOptionAreaOfExpertise(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    handleChange(info) {
      this.userCopy.avatar = info.file.originFileObj
      getBase64(info.file.originFileObj, imageUrl => {
        this.imageUrl = imageUrl
        this.loading = false
      })
    },
    async handleChangeForProof(info) {
      this.loadingProof = false
      const file = info.target.files[0]
      this.userCopy.proofOfPayment = file
      const fd = new FormData()
      fd.append('proofOfPayment', this.userCopy.proofOfPayment)
      const req = await userApi(this.axios).updateProfileSingleData({
        data: fd
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to upload file',
          description: req.message
        })
        this.loadingBarCert = false
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: 'Proof of payment uploaded'
      })
      this.loadingProof = false
    },
    async handleChangeCACDocument(info) {
      this.loadingCAC = false
      const file = info.target.files[0]
      this.userCopy.proofOfPayment = file
      const fd = new FormData()
      fd.append('cacDocument', this.userCopy.cacDocument)
      const req = await userApi(this.axios).updateProfileSingleData({
        data: fd
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to upload file',
          description: req.message
        })
        this.loadingBarCert = false
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: 'CAC Document uploaded'
      })
      this.loadingCAC = false
    },
    async handleBarCertificate(info) {
      this.loadingBarCert = true
      const file = info.target.files[0]
      this.userCopy.callToBarCertificate = file
      const fd = new FormData()
      fd.append('callToBarCertificate', this.userCopy.callToBarCertificate)
      const req = await userApi(this.axios).updateProfileSingleData({
        data: fd
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to upload file',
          description: req.message
        })
        this.loadingBarCert = false
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: 'Call to Bar Certificate uploaded'
      })
      this.loadingBarCert = false
    },
    async handleChangeForMultiple(info) {
      this.loadingBarCert = true
      const file = info.target.files[0]
      const fd = new FormData()
      fd.append('otherCertificates', file)
      const req = await userApi(this.axios).updateProfileSingleData({
        data: fd
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to upload file',
          description: req.message
        })
        this.loadingBarCert = false
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message
      })
      this.loadingBarCert = false
    }
  }
}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
